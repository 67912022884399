<template>
    <div>
        <h1>Card Holder Details</h1>
        <table id="cardHolderDetails">
            <thead>
                <tr>
                    <th
                        v-for="(_, idx) in cols.length"
                        :key="idx"
                    >
                        {{ cols[idx] }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(_, rowIdx) in rows.length"
                    :key="rowIdx"
                >
                    <td
                        v-for="(_, colIdx) in cols.length"
                        :key="colIdx"
                    >
                        {{ rows[rowIdx][cols[colIdx]] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { httpClient } from '@/services/http-client'

    export default {
        name: 'CardHolderDetails',
        data() {
            return {
                rows: [],
            }
        },
        mounted: async function () {
            const response = await httpClient.post('/avenCoreCardGetCardHolderDetail')

            const details = response.data.payload
            console.log(`/avenCoreCardGetCardHolderDetail ${JSON.stringify(details, null, 2)}`)

            if (!response.data.success) {
                console.error(response.data.error)
                alert(response.data.error)
            }

            let address = details.AddressGroup.AddressLine1 + ', '
            if (details.AddressGroup.AddressLine2 !== null) {
                address += details.AddressGroup.AddressLine2 + ', '
            }
            address += details.AddressGroup.City + ', ' + details.AddressGroup.State + ', ' + details.AddressGroup.Country + ', '
            address += details.AddressGroup.PostalCode

            this.rows.push({
                Description: 'Customer ID',
                Details: details.CustomerGroup.CustomerID,
            })
            this.rows.push({
                Description: 'First Name',
                Details: details.CustomerGroup.FirstName,
            })
            this.rows.push({
                Description: 'Middle Name',
                Details: details.CustomerGroup.MiddleName,
            })
            this.rows.push({
                Description: 'Last Name',
                Details: details.CustomerGroup.LastName,
            })
            this.rows.push({
                Description: 'Home Phone Number',
                Details: details.CustomerGroup.HomePhoneNumber,
            })
            this.rows.push({
                Description: 'Work Phone Number',
                Details: details.CustomerGroup.WorkPhoneNumber,
            })
            this.rows.push({
                Description: 'Mobile Number',
                Details: details.CustomerGroup.MobileNumber,
            })
            this.rows.push({
                Description: 'Date Of Birth',
                Details: details.CustomerGroup.DateOfBirth,
            })
            this.rows.push({
                Description: 'Address',
                Details: address,
            })
        },
        computed: {
            cols() {
                if (this.rows.length === 0) {
                    return []
                }
                return Object.keys(this.rows[0])
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';
</style>
